import React, { useState, useEffect } from 'react'
import AdminDashboardNav from '../AdminDashboardNav'
import fb from "../../Firebase/firebase";
import { useNavigate } from 'react-router-dom';
const DB = fb.firestore()
const Contact = DB.collection('B-Marco_Form');

const BMarco = () => {

    const [cf, SetCf] = useState([]);
    let navigate = useNavigate();
    let id;

    useEffect(() => {

        fb.auth().onAuthStateChanged(function (user) {
            if (user) {
                id = user.uid;
                navigate('/b-marco');
                console.log("uid", id);
            } else {
                navigate('/admin-login');
                console.log("user is logged out")
            }
        });

        const unsubscribe = Contact.onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            SetCf(data);
        });

        // Detach listener
        return unsubscribe;
    }, []);

    return (
        <div>
            <div className='dashboard'>
                <AdminDashboardNav />
                <div className='dashboard-app'>
                    <h3>Form Responses of B-Marco</h3>

                    <div className='contact-form'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Quantity</th>
                                    <th>Retailer / Self-Use</th>
                                </tr>
                            </thead>
                            {cf.map(contact => (
                                <tbody key={contact.id}>
                                    <tr>
                                        <td data-column="Name">{contact.Name}</td>
                                        <td data-column="Email">{contact.Email}</td>
                                        <td data-column="Phone">{contact.Mobile}</td>
                                        <td data-column="Quantity">{contact.Quantity}</td>
                                        <td style={{ textTransform: 'capitalize' }} data-column="RetailerSelfUse">{contact.RetailerSelfUse}</td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BMarco
