import React from 'react'
import Title from './Title'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Brands = () => {

    const brands = [
        [
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573079/Akcent%20HealthCare/Brands/Welltoo-pro_vyjdxq.webp",
                alt: "Welltoo-Pro"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573078/Akcent%20HealthCare/Brands/Veinosil_hwsyt4.webp",
                alt: "Veinosil"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573073/Akcent%20HealthCare/Brands/Rastrol_ruk7so.webp",
                alt: "Rastrol"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573072/Akcent%20HealthCare/Brands/qmarco_eerzr1.webp",
                alt: "Qmarco"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573069/Akcent%20HealthCare/Brands/Oncarbi_yvnidz.webp",
                alt: "Oncarbi"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573067/Akcent%20HealthCare/Brands/Murine_cwxety.webp",
                alt: "Murine"
            },
        ],
        [
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573062/Akcent%20HealthCare/Brands/Celesta-forte_ifojca.webp",
                alt: "Celesta-Forte"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573061/Akcent%20HealthCare/Brands/Callaxo_icfibl.webp",
                alt: "Callaxo"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573057/Akcent%20HealthCare/Brands/Axcelto_o88ufv.webp",
                alt: "Axcelto"
            }, {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573076/Akcent%20HealthCare/Brands/Vakion_reumtm.webp",
                alt: "Vakion-400"
            },

            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573075/Akcent%20HealthCare/Brands/Trimyou_k6m3xv.webp",
                alt: "Trimyou"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573070/Akcent%20HealthCare/Brands/Proviam_ndinqg.webp",
                alt: "Proviam"
            },
        ],
        [
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573066/Akcent%20HealthCare/Brands/Meluktor_ej6oz5.webp",
                alt: "Meluktor-500"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573065/Akcent%20HealthCare/Brands/Enrowin_hd02wx.webp",
                alt: "Enrowin-500"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573064/Akcent%20HealthCare/Brands/Emarco_elodg4.webp",
                alt: "Emarco"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573063/Akcent%20HealthCare/Brands/Collared_xxplwb.webp",
                alt: "Collared"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573509/Akcent%20HealthCare/Brands/B-Marco_ggnmq1.webp",
                alt: "B-Marco"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573060/Akcent%20HealthCare/Brands/Betkas-N3_ducfem.webp",
                alt: "Betkas-N3"
            },
        ],
        [
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573059/Akcent%20HealthCare/Brands/Beklair_cunzta.webp",
                alt: "Beklair"
            },
            {
                img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705573057/Akcent%20HealthCare/Brands/Aksam_tgcc7k.webp",
                alt: "Aksam-400"
            },
        ]
    ]

    return (
        <div className='brands'>
            <Title title="Brands" />

            <div className="brands-body">
                <AnimationOnScroll animateIn="animate__slideInLeft">
                    <div className="carousel">
                        <div class="carousel-wrapper">
                            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} swipeable={false}	>
                                {brands.map((item) => (
                                    <div className='carousel-body'>
                                        {item.map((data) => (
                                            <div className="testimonial-card">
                                                <img src={data.img} alt={data.alt} />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </AnimationOnScroll>
            </div>
        </div>
    )
}

export default Brands
